import React, { useState, useEffect } from 'react'
import { Typography, createStyles, withStyles, Box } from '@material-ui/core'

const styles = (theme) => {
  return createStyles({
    subHeader: {
      // color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontSize: '26px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '24px',
      },
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '1.44',
      marginBottom: '25px',
      marginTop: '65px',
    },
    text: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '1.44',
    },
  })
}

const Content = ({ classes }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    let searchParams = new URLSearchParams(window && window.location.search)
    const hasMobileQuery = searchParams.has('mobile')
    setIsMobile(hasMobileQuery)
  }, [])

  return (
    <Typography style={{
      color: isMobile && '#000000',
    }}>
      <p className={classes.subHeader}>
        Avocado Finance Protects Your Information
      </p>
      <p>
        We use a multi-layer security approach and use 256-bit encryption, the same security protocol used by the major financial institutions. We never store your bank password, it’s only used when first connecting to your financial institution account.
      </p>
      <p className={classes.subHeader}>
        Account Protection & Verification
      </p>
      <p>
        We use pin, email verification, and phone verification to secure your access.
      </p>
      <p className={classes.subHeader}>
        Multi-Factor Authentication & More
      </p>
      <p>
        We implement multi-factor authentication and automatic logouts to help prevent unauthorized access.
      </p>
      <p className={classes.subHeader}>
        Personal Data and Activity Protection
      </p>
      <p>
        From the time you submit your email address and password, communications between you and Avocado Finance are encrypted. This enables you and our server applications to communicate in a way that is designed to prevent eavesdropping and tampering.
      </p>
      <p className={classes.subHeader}>
        Security Support
      </p>
      <p>
        If you have any questions or see any suspicious activity on your account, please contact <span style={{ color: '#FF0080', fontWeight: '800' }}>support@avocadofinance.io</span>.
      </p>
    </Typography>
  )
}

export default withStyles(styles)(Content)

